<template>
    <div class="jc-component__range">
        <div class="jc-range" :class="rangeStatus?'success':''">
            <!--滑块的图标-->
            <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon" ref="sliderIcon" v-if="clientType==0"/>
            <i @touchmove="phoneRangeMove" :class="rangeStatus?successIcon:startIcon" ref="sliderIcon" v-else/>
            <!--滑块的文字-->
            {{rangeStatus?$t('login.sliderSuccessText'):$t('login.sliderStartText')}}
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance,reactive,toRefs,defineComponent,onMounted} from 'vue';
    export default defineComponent({
        props: {
            // 成功之后的函数
            successFun: {
                type: Function
            },
            //成功图标
            successIcon: {
                type: String,
                default: "iconfont icon-chenggong"
            },
            //成功文字
            successText: {
                type: String,
                default: "验证成功"
            },
            //开始的图标
            startIcon: {
                type: String,
                default: "iconfont icon-huakuai"
            },
            //开始的文字
            startText: {
                type: String,
                default: "拖动滑块到最右边"
            },
            //失败之后的函数
            errorFun: {
                type: Function
            },
            //或者用值来进行监听
            status: {
                type: String
            }
        },
        name: "Slider",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            let dataObj=reactive({
                sliderIcon:'',//滑块图标引用
                rangeStatus:'',//滑块状态
                disX:0,
                clientType:0//0-pc 1-手机
            })
            onMounted(()=>{
                //判断是pc还是手机。pc-flag为null；手机有值
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                if(flag)dataObj.clientType=1;
            })
            //滑块鼠标按下事件
            const rangeMove=(e)=>{
                let ele = e.target;
                let startX = e.clientX;
                let eleWidth = ele.offsetWidth;//滑块的宽度
                let parentWidth =  ele.parentElement.offsetWidth;//滑块父类div的宽度
                let MaxX = parentWidth - eleWidth;//终点位置(滑块父类div的宽度-滑块的宽度极为最终滑块的位置)
                if(dataObj.rangeStatus){//如果当前滑块的状态为验证通过状态，则不允许再次拖动图标了
                    return false;
                }
                //滑块按住滑动事件，计算滑动的最终位置
                document.onmousemove = (e) => {
                    let endX = e.clientX;
                    dataObj.disX = endX - startX;
                    if(dataObj.disX<=0){
                        dataObj.disX = 0;
                    }
                    if(dataObj.disX>=MaxX-eleWidth){//减去滑块的宽度,体验效果更好
                        dataObj.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+dataObj.disX+'px)';
                    e.preventDefault();
                }
                document.onmouseup = ()=> {
                    if(dataObj.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        //执行失败的函数
                        props.errorFun && props.errorFun();
                    }else{
                        dataObj.rangeStatus = true;
                        if(props.status){
                            proxy.$parent[props.status] = true;
                        }
                        //执行成功的函数
                        props.successFun && props.successFun();
                    }
                    document.onmousemove = null;
                    document.onmouseup = null;
                }
            }
            //手机拖动
            const phoneRangeMove=(e1)=>{
                let ele = e1.target;
                let eleWidth = ele.offsetWidth;//滑块的宽度
                let parentWidth =  ele.parentElement.offsetWidth;//滑块父类div的宽度
                let MaxX = parentWidth - eleWidth;//终点位置(滑块父类div的宽度-滑块的宽度极为最终滑块的位置)
                if(dataObj.rangeStatus){//如果当前滑块的状态为验证通过状态，则不允许再次拖动图标了
                    return false;
                }
                //滑块按住滑动事件，计算滑动的最终位置
                document.ontouchmove  = (e) => {
                    let endX = e.touches[0].clientX;
                    dataObj.disX = endX;
                    if(dataObj.disX<=0){
                        dataObj.disX = 0;
                    }
                    if(dataObj.disX>=MaxX-eleWidth){//减去滑块的宽度,体验效果更好
                        dataObj.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+dataObj.disX+'px)';
                }
                document.ontouchend  = ()=> {
                    if(dataObj.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        //执行失败的函数
                        props.errorFun && props.errorFun();
                    }else{
                        dataObj.rangeStatus = true;
                        if(props.status)proxy.$parent[props.status] = true;
                        //执行成功的函数
                        props.successFun && props.successFun();
                    }
                    document.ontouchmove = null;
                    document.ontouchend = null;
                }
            }
            //把滑块复原
            const resetSlider=()=>{
                dataObj.rangeStatus='';
                dataObj.disX=0;
                dataObj.sliderIcon.style.transform = 'translateX(0)';
            }
            return{
                ...toRefs(dataObj),rangeMove,phoneRangeMove,resetSlider
            }
        }
    });
</script>
<style scoped lang="scss">
    @import "src/style/global";
    /*由于设置了form-item的margin-buttom为0，会造成登录按钮和滑动验证组件挤在一起了，所以在这里往下挤8px*/
    .jc-component__range{
        margin-bottom: 8px;
        width: 100%;
        .jc-range{
            background-color: #FFCCCC;
            position: relative;
            transition: 1s all;
            user-select: none;
            color: #333;
            @include flexAlign('',center,center);
            height: 45px; /*no*/
        }
        i{
            position: absolute;
            left: 0;
            width: 60px;/*no*/
            height: 100%;
            color: #919191;
            background-color: #fff;
            border: 1px solid #bbb;
            cursor: pointer;
            @include flexAlign('',center,center);
        }
    }

    .jc-range.success{
        background-color: #7AC23C;
        color: #fff;
    }
    .jc-range.success i{
        color: #7AC23C;
    }
</style>